// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgHelpCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.75 2.247a6.938 6.938 0 1 0 0 13.875 6.938 6.938 0 0 0 0-13.875M1.687 9.184a8.063 8.063 0 1 1 16.126 0 8.063 8.063 0 0 1-16.125 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.508 5.886c-.258.22-.445.554-.445 1.048a.563.563 0 0 1-1.126 0c0-.818.329-1.468.844-1.905.502-.427 1.153-.626 1.782-.626.628 0 1.278.199 1.781.626.516.437.844 1.087.844 1.905 0 .574-.148 1.012-.388 1.373-.202.303-.464.537-.67.721l-.066.059c-.23.207-.404.378-.531.603-.122.215-.22.515-.22.994a.562.562 0 1 1-1.126 0c0-.646.137-1.143.366-1.549.225-.396.52-.67.758-.885l.054-.048c.226-.203.38-.342.499-.52.111-.166.198-.385.198-.748 0-.494-.187-.828-.445-1.048-.271-.23-.652-.358-1.055-.358s-.783.129-1.054.358m1.626 7.379c.23.208.25.563.042.794l-.008.009a.562.562 0 1 1-.836-.753l.007-.008a.563.563 0 0 1 .795-.042"
      clipRule="evenodd"
    />
  </svg>
);
export const HelpCircleIcon = forwardRef(SvgHelpCircle);
